<script setup lang="ts">
import { USER_TYPE } from "@/constants/index";
import UserOptions from "@/components/marketing/UserOptions.vue";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import AppLogo from "@/layouts/partials/marketing/AppLogo.vue";

interface Emit {
  (event: "candidateAuthSelected"): void;
  (event: "employerRegisterSelected"): void;
}

interface Props {
  navigation: any[];
  dropDownMenus: any[];
}

const emit = defineEmits<Emit>();
defineProps<Props>();

const user = useSupabaseUser();
const mobileMenuOpen = ref(false);
const optionsMenuOpen = ref(false);
const employerRoute = ref("");

const toggleOptionsMenu = (active: boolean, route?: string) => {
  if (route) employerRoute.value = route;
  optionsMenuOpen.value = active;
};

const toggleMobileMenu = (active: boolean) => {
  mobileMenuOpen.value = active;
};

const closeAll = () => {
  toggleOptionsMenu(false);
  toggleMobileMenu(false);
};

const mobileMenuHandler = (param: string | (() => void)) => {
  closeAll();
  if (typeof param === "string") return navigateTo(param);
  return param();
};

const handleUser = (type: USER_TYPE) => {
  switch (type) {
    case USER_TYPE.employer:
      if (employerRoute.value === ROUTES.register) emit("employerRegisterSelected");
      navigateTo(employerRoute.value);
      break;
    case USER_TYPE.candidate:
      closeAll();
      emit("candidateAuthSelected");
      break;
  }
};
</script>

<template>
  <div class="flex lg:hidden">
    <div>
      <button
        type="button"
        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-medium-gray"
        @click="toggleMobileMenu(true)"
      >
        <span class="sr-only">Open main menu</span>
        <Icon name="fa6-solid:bars" class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
    <TransitionRoot :show="mobileMenuOpen" as="template">
      <Dialog class="lg:hidden text-dark-gray" static>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-y-full"
          enter-to="-translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="-translate-y-0"
          leave-to="-translate-y-full"
        >
          <DialogPanel class="fixed inset-y-0 z-[40] w-full overflow-y-auto bg-white px-6 py-4">
            <div class="flex items-center justify-between">
              <div @click="mobileMenuHandler(ROUTES.home)" class="-m-1.5 p-1.5">
                <span class="sr-only">{{ $config.public.appEmail }}</span>
                <AppLogo />
              </div>
              <button
                type="button"
                class="-m-2.5 rounded-md p-2.5 text-medium-gray"
                @click="toggleMobileMenu(false)"
              >
                <span class="sr-only">Close menu</span>

                <Icon name="fa6-solid:xmark" class="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-light-gray">
                <div class="space-y-2 py-6">
                  <Disclosure
                    v-for="(menu, index) in dropDownMenus"
                    :key="index"
                    as="div"
                    class="-mx-3"
                    v-slot="{ open }"
                  >
                    <DisclosureButton
                      class="flex w-full items-center justify-between transition-colors rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7"
                      @click="toggleOptionsMenu(false)"
                    >
                      {{ menu.name }}
                      <Icon
                        name="fa6-solid:chevron-down"
                        :class="{ 'rotate-180': open }"
                        class="h-3 w-3 text-medium-gray transition-transform"
                        aria-hidden="true"
                      />
                    </DisclosureButton>
                    <transition
                      enter-active-class="transition duration-300 ease-out"
                      enter-from-class="transform scale-95 opacity-0"
                      enter-to-class="transform scale-100 opacity-100"
                      leave-active-class="transition duration-75 ease-out"
                      leave-from-class="transform scale-100 opacity-100"
                      leave-to-class="transform scale-95 opacity-0"
                    >
                      <DisclosurePanel class="mt-2 space-y-2 bg-gray-100 rounded-lg">
                        <DisclosureButton
                          v-for="item in menu.items"
                          :key="item.name"
                          as="div"
                          @click="mobileMenuHandler(item.action ?? item.href)"
                          class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:bg-gray-50"
                          >{{ item.name }}</DisclosureButton
                        >
                        <div
                          v-if="menu.cta"
                          class="bg-gray-100 p-5 sm:p-8 rounded-b-lg border-t-light-gray border"
                        >
                          <div
                            @click="mobileMenuHandler(menu.cta.href)"
                            class="-m-3 flow-root rounded-md group transition duration-150 ease-in-out"
                          >
                            <div class="flex flex-col">
                              <span class="text-base font-medium">{{ menu.cta.name }}</span>
                              <span class="mt-1 block text-sm text-gray-500">{{ menu.cta.desc }}</span>
                            </div>
                          </div>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                  <div
                    v-for="item in navigation"
                    :key="item.name"
                    :to="item.href"
                    @click="mobileMenuHandler(item.href)"
                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-100"
                  >
                    {{ item.name }}
                  </div>
                </div>

                <div v-if="!user" class="py-6 flex gap-3">
                  <OutlineButton
                    @click="toggleOptionsMenu(true, ROUTES.register)"
                    class="group w-full sm:w-auto rounded-full border-primary/30 hover:border-primary/70 motion-safe:hover:scale-105"
                  >
                    <span class="flex items-center">
                      Sign up
                      <Icon
                        name="material-symbols:chevron-right-rounded"
                        class="ml-1.5 group-hover:translate-x-1 transition-transform duration-300"
                      />
                    </span>
                  </OutlineButton>
                  <PrimaryButton
                    @click="toggleOptionsMenu(true, ROUTES.login)"
                    class="group w-full sm:w-auto rounded-full shadow-lg hover:shadow-xl transition-all duration-300 motion-safe:hover:scale-105"
                  >
                    <span class="flex items-center">
                      Sign in
                      <Icon
                        name="material-symbols:chevron-right-rounded"
                        class="ml-1.5 group-hover:translate-x-1 transition-transform duration-300"
                      />
                    </span>
                  </PrimaryButton>

                 
                </div>
                <NuxtLink class="py-6 w-full flex" v-else :to="ROUTES.dashboardHome">
                  <PrimaryButton
                    class="group w-full sm:w-auto rounded-full shadow-lg hover:shadow-xl transition-all duration-300 motion-safe:hover:scale-105"
                  >
                    <span class="flex items-center">
                      Dashboard
                      <Icon
                        name="material-symbols:chevron-right-rounded"
                        class="ml-1.5 group-hover:translate-x-1 transition-transform duration-300"
                      />
                    </span>
                  </PrimaryButton>
                </NuxtLink>
              </div>
            </div>
            <div>
              <transition name="fade" mode="out-in">
                <div
                  v-if="optionsMenuOpen"
                  @click="toggleOptionsMenu(false)"
                  class="fixed inset-0 bg-black bg-opacity-25"
                />
              </transition>
              <transition
                enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from-class="translate-y-full"
                enter-to-class="translate-y-0"
                leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                leave-from-class="translate-y-0"
                leave-to-class="translate-y-full"
              >
                <div
                  v-if="optionsMenuOpen"
                  class="fixed bottom-0 left-0 flex h-fit flex-col w-screen overflow-y-scroll bg-primary py-6 shadow-xl"
                >
                  <div class="px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <p class="text-base font-semibold leading-6 text-white">Looking to hire or be hired?</p>

                      <div @click="toggleOptionsMenu(false)">
                        <span class="sr-only">Close panel</span>
                        <Icon
                          name="fa6-solid:xmark"
                          class="w-5 h-5 text-white hover:text-primary cursor-pointer transition-all hover:rotate-180"
                        />
                      </div>
                    </div>
                    <div class="mt-6 flex-1">
                      <UserOptions @selected="(userType) => handleUser(userType)" />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
